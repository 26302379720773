/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { RecoilRoot } from 'recoil';
import { css } from '@emotion/core';
import PropagateLoader from 'react-spinners/PropagateLoader';

import LangSelect from './src/hoc/LangSelect';
import ErrorBoundary from './src/hoc/ErrorBoundary';
import ErrorPage from './src/pages_/errorPage/error.page';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const wrapPageElement = ({ element, props }) => {
  const override = css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20%;
  `;

  return (
    <RecoilRoot>
      <ErrorBoundary ErrorComponent={ErrorPage}>
        <React.Suspense
          fallback={
            <div css={override}>
              <PropagateLoader color={'#f47e20'} css={override} loading={true} />
            </div>
          }
        >
          <LangSelect lang={props.pageContext.lang}>{element}</LangSelect>
        </React.Suspense>
      </ErrorBoundary>
    </RecoilRoot>
  );
};

export { wrapRootElement } from './gatsby-ssr';
