// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-404-page-tsx": () => import("./../../../src/pages_/404/404.page.tsx" /* webpackChunkName: "component---src-pages-404-404-page-tsx" */),
  "component---src-pages-410-410-page-tsx": () => import("./../../../src/pages_/410/410.page.tsx" /* webpackChunkName: "component---src-pages-410-410-page-tsx" */),
  "component---src-pages-about-new-about-page-tsx": () => import("./../../../src/pages_/about/new-about.page.tsx" /* webpackChunkName: "component---src-pages-about-new-about-page-tsx" */),
  "component---src-pages-codex-codex-page-tsx": () => import("./../../../src/pages_/codex/codex.page.tsx" /* webpackChunkName: "component---src-pages-codex-codex-page-tsx" */),
  "component---src-pages-faq-faq-page-tsx": () => import("./../../../src/pages_/faq/faq.page.tsx" /* webpackChunkName: "component---src-pages-faq-faq-page-tsx" */),
  "component---src-pages-home-home-page-tsx": () => import("./../../../src/pages_/home/home.page.tsx" /* webpackChunkName: "component---src-pages-home-home-page-tsx" */),
  "component---src-pages-partnership-new-partnership-page-tsx": () => import("./../../../src/pages_/partnership/new-partnership.page.tsx" /* webpackChunkName: "component---src-pages-partnership-new-partnership-page-tsx" */),
  "component---src-pages-personal-rights-personal-rights-page-tsx": () => import("./../../../src/pages_/personal-rights/personal-rights.page.tsx" /* webpackChunkName: "component---src-pages-personal-rights-personal-rights-page-tsx" */),
  "component---src-pages-roadside-assistance-roadside-assistance-page-tsx": () => import("./../../../src/pages_/roadside-assistance/roadside-assistance.page.tsx" /* webpackChunkName: "component---src-pages-roadside-assistance-roadside-assistance-page-tsx" */),
  "component---src-pages-temp-temp-page-tsx": () => import("./../../../src/pages_/temp/temp.page.tsx" /* webpackChunkName: "component---src-pages-temp-temp-page-tsx" */),
  "component---src-pages-terms-terms-page-tsx": () => import("./../../../src/pages_/terms/terms.page.tsx" /* webpackChunkName: "component---src-pages-terms-terms-page-tsx" */),
  "component---src-pages-terms-terms-pdf-page-tsx": () => import("./../../../src/pages_/terms/termsPDF.page.tsx" /* webpackChunkName: "component---src-pages-terms-terms-pdf-page-tsx" */),
  "component---src-pages-unsubscribe-unsubscribe-page-tsx": () => import("./../../../src/pages_/unsubscribe/unsubscribe.page.tsx" /* webpackChunkName: "component---src-pages-unsubscribe-unsubscribe-page-tsx" */)
}

