export const countryLangs = {
  rs: 'sr',
  me: 'me',
  mk: 'mk',
  hr: 'hr',
  ba: 'bs',
  si: 'sl',
  al: 'sq',
  gr: 'el',
  hu: 'hu',
  bg: 'bg',
  ro: 'ro',
  eu: 'en',
  default: 'en',
};
