/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-key */

/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

import React from 'react';
import { RecoilRoot } from 'recoil';
import { I18nextProvider } from 'react-i18next';

import i18n from './src/i18n/i18n';
import { siteMetadata } from './gatsby-config';
import { COUNTRY } from './src/configs/env';
import { countryLangs } from './src/configs/country-langs';
import { resources } from './src/i18n/i18n';
import LangSelect from './src/hoc/LangSelect';
import ErrorBoundary from './src/hoc/ErrorBoundary';
import ErrorPage from './src/pages_/errorPage/error.page';

export const onRenderBody = ({ pathname, setHeadComponents }) => {
  /**
   * IMPORTANT NOTE for developers:
   *    This will NOT render correct canonical URLs in development mode.
   *    You need to build static pages inside the public folder (e.g. yarn build),
   *    and serve them, in order to test canonical urls.
   */

  let myPathName = pathname;

  // For some reason, the createPage action from gatsby-node, sends '/lang/undefined' for '' path,
  // ... even though if you console.log `path` in createPage, it logs it normally as an empty string.
  if (myPathName.includes('undefined')) {
    myPathName = myPathName.replace('undefined', '');
  }

  // Make search engines see '/' path as an intentional copy of '/lang'.
  if (myPathName === '/') {
    myPathName = `/${countryLangs[COUNTRY]}/`;
  }

  let url = `https://${COUNTRY}.${siteMetadata.rootSiteUrl}${myPathName}`;

  // not proud of this ¯\_(ツ)_/¯
  if (url.includes('pdf/uslovi-koriscenja') || url.includes('terms-of-use-doc')) {
    const lang = countryLangs[COUNTRY];
    const translationStrings = resources[lang].translation;
    const termsAndConditionsLink = translationStrings.link_termsAndConditions;

    url = `https://${COUNTRY}.${siteMetadata.rootSiteUrl}${termsAndConditionsLink}`;
  }

  setHeadComponents([<link rel="canonical" href={url} />]);
  setHeadComponents([<meta property="og:url" content={url} />]);
};

export const wrapRootElement = ({ element }) => {
  return <I18nextProvider i18n={i18n}>{element}</I18nextProvider>;
};

export const wrapPageElement = ({ element, props }) => {
  return (
    <RecoilRoot>
      <ErrorBoundary ErrorComponent={ErrorPage}>
        <LangSelect lang={props.pageContext.lang}>{element}</LangSelect>
      </ErrorBoundary>
    </RecoilRoot>
  );
};
