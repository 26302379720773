import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { COUNTRY } from '../configs/env';
import { countryLangs } from '../configs/country-langs.js';

import sr from './locales/sr.json';
import me from './locales/me.json';
import mk from './locales/mk.json';
import hr from './locales/hr.json';
import bs from './locales/bs.json';
import sl from './locales/sl.json';
import sq from './locales/sq.json';
import el from './locales/el.json';
import hu from './locales/hu.json';
import ro from './locales/ro.json';
// import enRs from './locales/en-rs.json';
// import enMe from './locales/en-me.json';
import bg from './locales/bg.json';
import en from './locales/en.json';

// const enTrans = {
//   rs: enRs,
//   me: enMe,
//   eu: en,
// };

export const resources = {
  en: {
    translation: en,
  },
  sr: {
    translation: sr,
  },
  me: {
    translation: me,
  },
  mk: {
    translation: mk,
  },
  hr: {
    translation: hr,
  },
  bs: {
    translation: bs,
  },
  sl: {
    translation: sl,
  },
  sq: {
    translation: sq,
  },
  el: {
    translation: el,
  },
  hu: {
    translation: hu,
  },
  bg: {
    translation: bg,
  },
  ro: {
    translation: ro,
  },
};

i18n.use(initReactI18next).init({
  resources: resources,
  lng: countryLangs[COUNTRY],
  fallbackLng: 'en',
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
