export const APIs = {
  mainServerApiUrl: process.env.GATSBY_VOZZI_SERVERAPIV2_API_URL,
  mainServerAppUrl: process.env.GATSBY_VOZZI_SERVERAPIV2_APP_URL,
};

export const COUNTRY = (process.env.GATSBY_VOZZI_COUNTRY || 'rs').toLowerCase();

export const ALLSECURE_URL = process.env.GATSBY_VOZZI_ALLSECURE_URL;

export const WEB_ROOT = {
  RS: process.env.GATSBY_VOZZI_RS_WEB_ROOT,
  ME: process.env.GATSBY_VOZZI_ME_WEB_ROOT,
  MK: process.env.GATSBY_VOZZI_MK_WEB_ROOT,
  HR: process.env.GATSBY_VOZZI_HR_WEB_ROOT,
  BA: process.env.GATSBY_VOZZI_BA_WEB_ROOT,
  SI: process.env.GATSBY_VOZZI_SI_WEB_ROOT,
  AL: process.env.GATSBY_VOZZI_AL_WEB_ROOT,
  GR: process.env.GATSBY_VOZZI_GR_WEB_ROOT,
  HU: process.env.GATSBY_VOZZI_HU_WEB_ROOT,
  BG: process.env.GATSBY_VOZZI_BG_WEB_ROOT,
  RO: process.env.GATSBY_VOZZI_RO_WEB_ROOT,
  EU: process.env.GATSBY_VOZZI_EU_WEB_ROOT,
};

export const GATSBY_GMAPS_API_KEY = process.env.GATSBY_GMAPS_API_KEY;
